.hrs-form__amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hrs-form__total {
  display: flex;
  align-items: center;
}

.hrs-form__securedImage img {
  width: 300px;
}

.hrs-form__securedImage {
  text-align: center;
}

@media (max-width: 960px) {
  .hrs-form__securedImage {
    margin-top: 50px !important;
  }
}
.hrs-form__securedImage {
  margin-top: 300px;
  text-align: center;
}

.hrs-form__creditCards {
  /* width: 80%; */
  padding-right: 32px;
  height: auto;
  overflow: hidden;
  min-height: 1.1876em;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 4px;
  cursor: pointer;
  min-width: 16px;
  user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  /* padding: 18.5px 14px; */
  margin-top: 10px;
  /* border: 1px solid #c4c4c4; */
  border-radius: 4px;
}

.hrs-form__creditCards img {
  /* padding-top: 5p */
  margin-top: 20px;
}

.hrs-form {
  width: 90%;
}

.hrs-form .MuiTextField-root {
  margin-top: 20px;
}

.hrs-form .month.year.cvc {
  /* display: flex;
  justify-content: space-between; */
  margin-top: 10px;
}

@import url(https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600;700&family=Montserrat:ital,wght@0,400;0,600;0,700;1,400&display=swap);
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  min-height: 100%;
}

* {
  box-sizing: border-box;
  /* color: #555555; */
}

body {
  margin: 0;
  font-family: "Hind", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  color: #555555;
}

#root {
  background-color: #f7f7f7;
  min-height: 100vh;
}

body > div {
  font-size: 20px;
  /* font-size: 1.8rem; */
  color: #555555;
}

p {
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #555555;
}

table thead th {
  font-weight: bold !important;
}

b,
strong {
  font-weight: 600;
}

blockquote,
cite,
em,
i {
  font-style: italic;
}

label {
  font-size: 20px !important;
  font-weight: bold;
  color: #555;
}

.quote-container span {
  font-size: 20px;
}

blockquote {
  margin: 1em 0;
  padding: 0 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #555555;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
}

/* text field border color override  */

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2188c9 !important;
}

.hrs-form__amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hrs-form__total {
  display: flex;
  align-items: center;
}

.hrs-form__securedImage img {
  width: 300px;
}

.hrs-form__securedImage {
  text-align: center;
}

@media (max-width: 960px) {
  .hrs-form__securedImage {
    margin-top: 50px !important;
  }
}
.hrs-form__securedImage {
  margin-top: 300px;
  text-align: center;
}

.hrs-form__creditCards {
  /* width: 80%; */
  padding-right: 32px;
  height: auto;
  overflow: hidden;
  min-height: 1.1876em;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 4px;
  cursor: pointer;
  min-width: 16px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  /* padding: 18.5px 14px; */
  margin-top: 10px;
  /* border: 1px solid #c4c4c4; */
  border-radius: 4px;
}

.hrs-form__creditCards img {
  /* padding-top: 5p */
  margin-top: 20px;
}

.hrs-form {
  width: 90%;
}

.hrs-form .MuiTextField-root {
  margin-top: 20px;
}

.hrs-form .month.year.cvc {
  /* display: flex;
  justify-content: space-between; */
  margin-top: 10px;
}

